import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useApi from "../../hooks/useApi";

import { ReactComponent as Single } from "../../static/images/single_schedule.svg";
import { ReactComponent as Group } from "../../static/images/group_schedule.svg";
import { ReactComponent as Passed } from "../../static/images/passed_history.svg";
import { ReactComponent as Canceled } from "../../static/images/canceled_history.svg";

import "./style.scss";

const ProviderBycustomerHistoryDetails = () => {
  const { get, loading } = useApi();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    scheduleId,
    startEventDate,
    endEventDate,
    userId,
    customerName,
    subId,
  } = location.state || {};

  const [scheduleData, setScheduleData] = useState([]);
  const [cardsData, setcardsData] = useState([]);

  const [monday, setMonday] = useState("");
  const [tuesday, setTuesday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThursday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [sunday, setSunday] = useState("");

  const months = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  const weekdays = [
    t("sunday"),
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
  ];

  function getMonthByIndex(index) {
    if (index >= 0 && index <= 11) {
      return months[index];
    }
  }

  const checkDetailsDays = (detailsData) => {
    detailsData.scheduleDays.forEach((day) => {
      if (day.dayOfWeek === "Monday") {
        setMonday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Tuesday") {
        setTuesday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Wednesday") {
        setWednesday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Thursday") {
        setThursday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Friday") {
        setFriday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Saturday") {
        setSaturday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Sunday") {
        setSunday(["active", day.timeOnly.slice(0, 5)]);
      }
    });
  };

  const fetchSchedule = async () => {
    try {
      const data = await get(`/Schedule/${scheduleId}`);

      setScheduleData(data);
      checkDetailsDays(data);
    } catch (err) {}
  };

  const fetchCardData = async () => {
    try {
      const data = await get(
        `/ApplicationEvent?SubscriptionId=${subId}&ScheduleId=${scheduleId}&EventDate=${startEventDate}&EndEventDate=${endEventDate}&EventStatuses=Cancelled&EventStatuses=Passed&ProviderBusinessAccountId=${userId}`,
      );

      setcardsData(data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchCardData();
    fetchSchedule();
  }, []);

  return (
    <div className="provider-bycustomer-details">
      <div className="description">
        <div className="description-icon">
          {scheduleData.groupSchedule === true ? <Group /> : <Single />}
        </div>
        <span>{scheduleData.description}</span>
      </div>

      <div className="weekdays">
        <div className={monday[0]}>
          <span>{t("monday").slice(0, 2)}</span> <span>{monday[1]}</span>
        </div>
        <div className={tuesday[0]}>
          {" "}
          <span>{t("tuesday").slice(0, 2)}</span> <span>{tuesday[1]}</span>
        </div>
        <div className={wednesday[0]}>
          {" "}
          <span>{t("wednesday").slice(0, 2)}</span> <span>{wednesday[1]}</span>
        </div>
        <div className={thursday[0]}>
          {" "}
          <span>{t("thursday").slice(0, 2)}</span> <span>{thursday[1]}</span>
        </div>
        <div className={friday[0]}>
          {" "}
          <span>{t("friday").slice(0, 2)}</span> <span>{friday[1]}</span>
        </div>
        <div className={saturday[0]}>
          {" "}
          <span>{t("saturday").slice(0, 2)}</span> <span>{saturday[1]}</span>
        </div>
        <div className={sunday[0]}>
          {" "}
          <span>{t("sunday").slice(0, 2)}</span> <span>{sunday[1]}</span>
        </div>
      </div>

      <div className="section-devider"></div>

      <div className="event-date">
        <h2>
          {getMonthByIndex(new Date(startEventDate).getMonth()) +
            " " +
            new Date(startEventDate).getFullYear()}
        </h2>
      </div>

      <div className="section-devider"></div>

      <div className="details-card-container">
        <ul>
          {cardsData &&
            cardsData.map((item) => {
              return (
                <li key={item.id} className="details-card-li">
                  <div className="details-card">
                    <div className="card-item">
                      <div className="card-item-content">
                        <div className="card-status">
                          {item.eventStatus === "Passed" ? (
                            <Passed />
                          ) : (
                            <Canceled />
                          )}
                        </div>

                        <div className="card-time">
                          <span>
                            {new Date(item.eventDate)
                              .getHours()
                              .toString()
                              .padStart(2, "0") +
                              ":" +
                              new Date(item.eventDate)
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}
                          </span>
                        </div>
                        <div className="card-weekday">
                          <span>
                            {weekdays[new Date(item.eventDate).getDay()].slice(
                              0,
                              3,
                            )}
                          </span>
                        </div>
                        <div className="card-date">
                          <span>
                            {new Date(item.eventDate).getDate() +
                              " " +
                              getMonthByIndex(
                                new Date(startEventDate).getMonth(),
                              ).slice(0, 3)}
                          </span>
                        </div>
                      </div>
                      <div className="card-name">{customerName}</div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <div
        className="back-btn"
        onClick={() => {
          navigate(-1);
        }}
      >
        <span>{t("go_previous")}</span>
      </div>

      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default ProviderBycustomerHistoryDetails;
