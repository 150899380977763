import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SandwichNavigation from "../SandwichNavigation/SandwichNavigation";

import { ReactComponent as RunIcon } from "../../static/images/run_icon.svg";

import "./style.css";

const Header = ({ sandwichToggle, active }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (
    pathname === "/" ||
    pathname === "/role-selector" ||
    pathname === "/signUp" ||
    pathname === "/signIn" ||
    pathname === "/usedInv" ||
    localStorage.ps10005000 === undefined
  ) {
    return;
  }

  return (
    <>
      <SandwichNavigation active={active} sandwichToggle={sandwichToggle} />
      <div className={`header`}>
        <div className="header-container">
          <div
            className="sandwich"
            onClick={() => {
              sandwichToggle("");
            }}
          >
            <div className="sandwich-line"></div>
            <div className="sandwich-line"></div>
            <div className="sandwich-line"></div>
          </div>
          <div
            className="title"
            onClick={() => {
              navigate("/sessions");
            }}
          >
            <h1>skedy</h1>
          </div>
          <div>
            {/* <img src={run_icon} alt="stickman" /> */}
            <RunIcon />
          </div>
        </div>
      </div>
    </>
  );
};

function areEqual(preValue, postValue) {
  if (preValue.sandwichToggle !== postValue.sandwichToggle) {
    return false;
  } else if (preValue.active !== postValue.active) {
    return false;
  } else {
    return true;
  }
}

export default React.memo(Header, areEqual);
