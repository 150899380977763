import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useApi from "../../hooks/useApi";
import { useModalStore } from "../../store/GlobalVarriables";

import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";
import ModalWarning from "../../Components/ModalWarning/ModalWarning";
import DotsMenu from "../../Components/DotsMenu";
import { monthNames } from "../../store/GlobalVarriables";

import { ReactComponent as Tick } from "../../static/images/tick_sign.svg";
import { ReactComponent as DuePayment } from "../../static/images/due_payment.svg";
import { ReactComponent as PayerBadge } from "../../static/images/payer_badge.svg";

import "./style.scss";

const SessionDetails = () => {
  const { get, del, put, patch, post, loading } = useApi();

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const {
    originalEventDate,
    dayId,
    eventId,
    NewScheduledDate,
    OriginalScheduledDate,
    NewScheduledTimeOnly,
    OriginalScheduledTimeOnly,
  } = location.state || {};

  const invRef = useRef(null);
  const subRef = useRef(null);

  const { sessionID } = useParams();

  const [scheduleDetail, setScheduleDetail] = useState([]);
  const [invitationData, setInvitationData] = useState([]);
  const [subDetails, setSubDetails] = useState([]);

  const [monday, setMonday] = useState("");
  const [tuesday, setTuesday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThursday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [sunday, setSunday] = useState("");

  const [invitationInputToggle, setInvitationInputToggle] = useModalStore("");

  const [confirmModalToggle, setConfirmModalToggle] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalSubText, setModalSubText] = useState("");
  const [modalValue, setModalValue] = useState(0); // for detect what action is needed

  const [warningModalToggle, setWarningModalToggle] = useState("");
  const [warningModalText, setWarningModalText] = useState("");
  const [warningButtonText, setWarningButtonText] = useState("");

  const [detailMenuToggle, setDetailMenuToggle] = useState(false);

  const [scheduleId, setScheduleId] = useState("");
  const [groupPayerId, setGroupPayerId] = useState("");
  const [currentPayer, setCurrentPayer] = useState([]);
  const [payerIdentifier, setPayerIdentifier] = useState([]);

  const menuItems = [
    {
      text: t("schedule_details"),
      onClick: () => {
        navigate(`/schedule/${sessionID}`);
      },
    },
    {
      text: t("invite_customer"),
      disabled: scheduleDetail.availableSeats <= 0,
      onClick: () => {
        handleValidations();
      },
    },
    {
      text: t("cancel_session"),
      onClick: () => handleMenuButton("cancel session"),
    },
    {
      text: t("reschedule_session"),
      onClick: () => {
        navigate(`/reschedule/${sessionID}`, {
          state: {
            originalEventDate,
            dayId,
            eventId,
          },
        });
      },
    },
  ];

  const fetchDetails = async () => {
    try {
      const data = await get(`/Schedule/${sessionID}`);
      setScheduleDetail(data);
      checkDetailsDays(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInvitation = async () => {
    try {
      const data = await get(`/Schedule/${sessionID}/Invitations`);
      if (data) {
        const initializedInvitationData = data.map((item) => ({
          invitedNumber: item.invitedPhoneNumber,
          invitedEmail: item.invitedEmailAddress,
          status: item.status,
          id: item.id,
          showMenu: false,
        }));

        setInvitationData(initializedInvitationData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSub = async () => {
    try {
      const data = await get(`/Schedule/${sessionID}/Subscriptions`);

      if (data) {
        const initializedSubscriptionData = data.map((item) => ({
          businessAccountName: item.businessAccount.name,
          overduePayment: item.overduePayment,
          id: item.id,
          isGroupPayer: item.isGroupPayer,
          showMenu: false,
        }));
        setSubDetails(initializedSubscriptionData);
        setCurrentPayer(data.filter((item) => item.isGroupPayer === true));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelDataFormat = (date) => {
    const localDate = new Date(date);
    localDate.setHours(localDate.getHours() + 4);
    return localDate.toISOString();
  };

  const cancelSession = async () => {
    try {
      const method = eventId ? patch : post;

      NewScheduledDate.setHours(NewScheduledTimeOnly.hour);
      NewScheduledDate.setMinutes(NewScheduledTimeOnly.minute);
      NewScheduledDate.setSeconds(NewScheduledTimeOnly.second || "00");
      OriginalScheduledDate.setHours(OriginalScheduledTimeOnly.hour);
      OriginalScheduledDate.setMinutes(OriginalScheduledTimeOnly.minute);
      OriginalScheduledDate.setSeconds(
        OriginalScheduledTimeOnly.second || "00",
      );

      const dataToPass = eventId
        ? {
            id: eventId,
            eventStatus: "Cancelled",
          }
        : {
            scheduleDayId: dayId,
            eventTime: cancelDataFormat(NewScheduledDate),
            originalEventTime: cancelDataFormat(OriginalScheduledDate),
            eventStatus: "Cancelled",
            scheduleId: +sessionID,
          };

      const data = await method("/ApplicationEvent", dataToPass);
      if (data) {
        setWarningModalToggle("modal-warning");
        setWarningModalText(t("session_cancel_success"));
        setWarningButtonText(t("go_main_page"));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleGroupPayer = async () => {
    if (currentPayer.length) {
      try {
        const data = await patch(`/Subscription`, {
          id: currentPayer[0].id,
          isGroupPayer: false,
        });
      } catch (error) {}
    }

    try {
      const data = await patch(`/Subscription`, {
        id: groupPayerId,
        isGroupPayer: true,
        // description: "string",
      });

      await fetchSub();
    } catch (error) {
      console.log(error);
    }
  };

  const checkDetailsDays = (detailsData) => {
    detailsData.scheduleDays.forEach((day) => {
      if (day.dayOfWeek === "Monday") {
        setMonday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Tuesday") {
        setTuesday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Wednesday") {
        setWednesday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Thursday") {
        setThursday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Friday") {
        setFriday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Saturday") {
        setSaturday(["active", day.timeOnly.slice(0, 5)]);
      }
      if (day.dayOfWeek === "Sunday") {
        setSunday(["active", day.timeOnly.slice(0, 5)]);
      }
    });
  };

  const getDataId = (id) => {
    setScheduleId(id);
  };

  const postInvitation = async () => {
    if (modalValue === 1) {
      cancelSession();
    } else if (modalValue === 2) {
      try {
        const data = await put(`/Invitation`, {
          id: scheduleId,
          operation: "Cancel",
        });

        setWarningModalToggle("modal-warning");
        setWarningModalText(t("cancel_success"));
        setWarningButtonText(t("go_schedule_details"));
      } catch (error) {
        console.log(error);
      }
    } else if (modalValue === 3) {
      try {
        const data = await del(`/Subscription/${scheduleId}/Cancel`);
        setWarningModalToggle("modal-warning");
        setWarningModalText(t("cancel_success"));
        setWarningButtonText(t("go_schedule_details"));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleValidations = () => {
    /// disappear invitation menu
    setInvitationData(
      invitationData.map((item, i) => ({
        ...item,
        showMenu: false,
      })),
    );

    /// disappear subscription menu
    setSubDetails(
      subDetails.map((item, i) => ({
        ...item,
        showMenu: false,
      })),
    );

    if (scheduleDetail.availableSeats > 0) {
      setInvitationInputToggle("invite");
      setDetailMenuToggle(false);

      navigate(`/schedule/${sessionID}`);
    }
  };

  const handleMenuButton = async (identity, payerItem) => {
    setDetailMenuToggle(false);

    if (identity === "cancel session") {
      setConfirmModalToggle("modal-confirm");
      setModalText(
        t("cancel_session_confirmation_modal_text", {
          date: formatDate(NewScheduledDate),
        }),
      );
      setModalSubText(t("cancel_session_confirmation_modal_subText"));
      setModalValue(1);
    } else if (identity === "cancel invitation") {
      setConfirmModalToggle("modal-confirm");
      setWarningModalText(t("cancel_success"));
      setWarningButtonText(t("go_schedule_details"));
      setModalValue(2);
      /// disappear invitation menu
      setInvitationData(
        invitationData.map((item, i) => ({
          ...item,
          showMenu: false,
        })),
      );
    } else if (identity === "cancel subscription") {
      setConfirmModalToggle("modal-confirm");
      setWarningModalText(t("cancel_success"));
      setWarningButtonText(t("go_schedule_details"));
      setModalValue(3);

      /// disappear subscription menu
      setSubDetails(
        subDetails.map((item, i) => ({
          ...item,
          showMenu: false,
        })),
      );
    } else if ("select as group payer") {
      if (payerIdentifier.isGroupPayer === true) {
        return;
      }

      setSubDetails(
        subDetails.map((item, i) => ({
          ...item,
          showMenu: false,
        })),
      );

      setConfirmModalToggle("modal-confirm");
      setModalText(
        t("add_into_group_confirmation_modal_text", {
          name: payerItem.businessAccountName,
          scheduleName: scheduleDetail.description.trim(""),
        }),
      );
      setModalSubText(t("add_into_group_confirmation_modal_subText"));
      setModalValue(4);
      setGroupPayerId(payerItem.id);
    }
  };

  const toggleMenu = (identity, index) => {
    if (identity === "invitation") {
      setInvitationData(
        invitationData.map((item, i) =>
          i === index
            ? { ...item, showMenu: !item.showMenu }
            : { ...item, showMenu: false },
        ),
      );

      /// disappear subscription menu
      setSubDetails(
        subDetails.map((item, i) => ({
          ...item,
          showMenu: false,
        })),
      );
    } else {
      setSubDetails(
        subDetails.map((item, i) =>
          i === index
            ? { ...item, showMenu: !item.showMenu }
            : { ...item, showMenu: false },
        ),
      );
      /// disappear invitation menu
      setInvitationData(
        invitationData.map((item, i) => ({
          ...item,
          showMenu: false,
        })),
      );
    }
  };

  const handleToggleDetailsMenu = () => {
    setDetailMenuToggle((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (invRef.current && !invRef.current.contains(event.target)) {
      setInvitationData(
        invitationData.map((item) => ({
          ...item,
          showMenu: false,
        })),
      );
    }
    if (subRef.current && !subRef.current.contains(event.target)) {
      setSubDetails(
        subDetails.map((item) => ({
          ...item,
          showMenu: false,
        })),
      );
    }
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = monthNames[date.getMonth()];

    const year = date.getFullYear();

    const formattedDate = `${day} of ${month} ${year}`;

    return formattedDate;
  };

  const warningButton = () => {
    fetchDetails();
    fetchSub();
    fetchInvitation();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [invitationData]);

  useEffect(() => {
    setInvitationInputToggle("");

    try {
      fetchDetails();
      fetchInvitation();
      fetchSub();
    } catch (err) {
      console.error("Error:", err);
    }
  }, []);

  return (
    <div className={`detail-page`}>
      <div className="details-container">
        <div className="details-description">
          <span>{scheduleDetail.description}</span>
          <div className="deatils-menu">
            <DotsMenu
              menuItems={menuItems}
              isMenuOpen={detailMenuToggle}
              toggleMenu={() => handleToggleDetailsMenu()}
            />
          </div>
        </div>

        <div className="details-days">
          <div className={monday[0]}>
            <span>{t("monday").slice(0, 2)}</span> <span>{monday[1]}</span>
          </div>
          <div className={tuesday[0]}>
            {" "}
            <span>{t("tuesday").slice(0, 2)}</span> <span>{tuesday[1]}</span>
          </div>
          <div className={wednesday[0]}>
            {" "}
            <span>{t("wednesday").slice(0, 2)}</span>{" "}
            <span>{wednesday[1]}</span>
          </div>
          <div className={thursday[0]}>
            {" "}
            <span>{t("thursday").slice(0, 2)}</span> <span>{thursday[1]}</span>
          </div>
          <div className={friday[0]}>
            {" "}
            <span>{t("friday").slice(0, 2)}</span> <span>{friday[1]}</span>
          </div>
          <div className={saturday[0]}>
            {" "}
            <span>{t("saturday").slice(0, 2)}</span> <span>{saturday[1]}</span>
          </div>
          <div className={sunday[0]}>
            {" "}
            <span>{t("sunday").slice(0, 2)}</span> <span>{sunday[1]}</span>
          </div>
        </div>
        <div className="date">
          <b>{formatDate(originalEventDate)}</b>
        </div>
        {invitationData.length > 0 ? (
          <div className={`sub-details-container ${invitationInputToggle}`}>
            {invitationData.map((item, index) => {
              return (
                <div
                  className="single-sub"
                  key={item.id}
                  onClick={() => {
                    getDataId(item.id);
                  }}
                >
                  <div className="single-sub-name">
                    {item.invitedNumber ? (
                      <h3>{item.invitedNumber}</h3>
                    ) : (
                      <h3>{item.invitedEmail}</h3>
                    )}
                  </div>
                  <div className="single-sub-icons">
                    <div className="single-sub-status">
                      <span>{item.status}</span>
                    </div>
                    <div className="details-settings">
                      <DotsMenu
                        menuItems={[
                          {
                            text: t("cancel_invitation"),
                            onClick: () =>
                              handleMenuButton("cancel invitation"),
                          },
                        ]}
                        isMenuOpen={item.showMenu}
                        toggleMenu={() => toggleMenu("invitation", index)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          subDetails.length === 0 && (
            <div className={`empty-data ${invitationInputToggle}`}>
              {t("details_dont_have_subscription")}
            </div>
          )
        )}

        {subDetails.length > 0 ? (
          <div className={`sub-details-container ${invitationInputToggle}`}>
            {subDetails.map((item, index) => {
              return (
                <div
                  className="single-sub"
                  key={item.id}
                  onClick={() => {
                    getDataId(item.id);
                    setPayerIdentifier(item);
                  }}
                >
                  <div className="single-sub-name">
                    <h3>{item.businessAccountName}</h3>
                  </div>
                  <div className="single-sub-icons">
                    {scheduleDetail?.financialDetails?.payerOption ===
                    "Group" ? (
                      <>
                        {item.isGroupPayer === true ? (
                          <div className="group-payer">
                            <PayerBadge />
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    {item.overduePayment === true ? (
                      <div className="group-payer">
                        <DuePayment />
                      </div>
                    ) : null}

                    {scheduleDetail?.financialDetails?.payerOption ===
                    "Group" ? (
                      <div className="details-settings">
                        <DotsMenu
                          menuItems={[
                            {
                              text: t("cancel_invitation"),
                              onClick: () =>
                                handleMenuButton("cancel subscription"),
                            },

                            {
                              text: t("select_as_group_payer"),
                              disabled:
                                payerIdentifier.isGroupPayer === true
                                  ? true
                                  : false,
                              onClick: () =>
                                handleMenuButton("select as group payer", item),
                            },
                          ]}
                          isMenuOpen={item.showMenu}
                          toggleMenu={() => toggleMenu("subscription", index)}
                        />
                      </div>
                    ) : (
                      <div className="details-settings">
                        <DotsMenu
                          menuItems={[
                            {
                              text: t("cancel_invitation"),
                              onClick: () =>
                                handleMenuButton("cancel subscription"),
                            },
                          ]}
                          isMenuOpen={item.showMenu}
                          toggleMenu={() => toggleMenu("subscription", index)}
                        />
                      </div>
                    )}
                  </div>{" "}
                </div>
              );
            })}
          </div>
        ) : null}

        <ModalConfirmation
          modalText={modalText}
          modalSubText={modalSubText}
          changeModal={setConfirmModalToggle}
          modalValue={confirmModalToggle}
          confirm={modalValue === 4 ? handleGroupPayer : postInvitation}
        />

        <ModalWarning
          warningText={warningModalText}
          buttonText={warningButtonText}
          changeModal={setWarningModalToggle}
          modalValue={warningModalToggle}
          Image={Tick}
          handleButton={warningButton}
        />
      </div>

      <div className="details-invite-buttons">
        <button
          id="details-invite"
          onClick={() => {
            handleValidations();
          }}
          disabled={scheduleDetail.availableSeats > 0 ? false : true}
          style={{
            opacity: scheduleDetail.availableSeats > 0 ? 1 : 0.5,
          }}
        >
          {t("invite_customer")}
        </button>
        <button
          id={`details-back`}
          onClick={(e) => {
            navigate(-1);
          }}
        >
          {t(invitationInputToggle === "invite" ? "cancel" : "back")}
        </button>
      </div>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default SessionDetails;
